import Vue from 'vue'
import VueRouter from 'vue-router'
// import index from '../views/index.vue'
import keji from '../views/keji.vue'
import youjiao from '../views/youjiao.vue'
import jinrong from '../views/jinrong.vue'
import zhijiao from '../views/zhijiao.vue'
import dianshang from '../views/dianshang.vue'
import news from '../views/news.vue'
import GroupNews from '../views/News/GroupNews.vue'
import MediaCoverage from '../views/News/MediaCoverage.vue'
import TradeNews from '../views/News/TradeNews.vue'
import zhaopinxinxi0 from '../views/zhaopinxinxi0.vue'
import zhaopinxinxi1 from '../views/Recruit-info/zhaopinxinxi1.vue'
import lianxi from '../views/lianxiwomen.vue'
import zhaopinxiangqing0_1 from '../views/Recruitment-details/zhaopinxiangqing0-1.vue'
import zhaopinxiangqing0_0 from '../views/Recruitment-details/zhaopinxiangqing0-0.vue'
import zhaopinxiangqing1_0 from '../views/Recruitment-details/zhaopinxiangqing1-0.vue'
import zhaopinxiangqing1_1 from '../views/Recruitment-details/zhaopinxiangqing1-1.vue'
import CommonService from '../service/CommonService'
// import index from '../views/Home.vue'
// const index=()=> import ('@/views/Home')
// const landDataHome=()=>import("@/views/LandDataHome/landData.vue")
// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: ()=> import ('@/views/Home'),
    meta: {
      title: '首页'
    }
  },
  //开始
  {
    path: '/tradingHall',
    name: 'tradingHall',
    component: ()=>import("@/views/TradingHall/index.vue"),
    meta: {
      title: '交易大厅'
    }
  },
  {
    path: '/demand',
    name: 'demand',
    component: ()=>import("@/views/demand.vue"),
    meta: {
      title: '需求用地'
    }
  },
  {
    path: '/demand/detail/:id',
    name: 'demand-detail',
    component: ()=>import("@/views/demand-detail.vue"),
    meta: {
      title: '求购详情'
    }
  },
  {
    path: '/rent/detail/:id',
    name: 'rent-detail',
    component: ()=>import("@/views/rent-detail.vue"),
    meta: {
      title: '求租详情'
    }
  },
  {
    path: '/stock',
    name: 'stock',
    component: ()=>import("@/views/supply/stock.vue"),
    meta: {
      title: '存量土地'
    }
  },
  {
    path: '/link',
    name: 'link',
    component: ()=>import("@/views/secure.vue"),
    meta: {
      title: '安全中心'
    }
  },
  {
     path: '/landDataHome',
     name: 'landDataHome',
     component: ()=>import("@/views/LandDataHome/landData.vue"),
     meta: {
       title: '土地数据'
     }
  },
  {
    path: '/information',
    name: 'information',
    component: ()=>import("@/views/information/information.vue"),
    meta: {
      title: '通知公告'
    }
  },
  {
    //transfer
    path: '/supply',
    name: 'supply',
    component: ()=>import("@/views/supply/supply.vue"),
    meta: {
      title: '供给用地'
    }
  },
  {
    //公告
    path: '/announcement',
    name: 'announcement',
    component: ()=>import("@/views/announcement.vue"),
    meta: {
      title: '公告'
    }
  },
  {
    // 交易服务
    path: '/tradingServices',
    name: 'tradingServices',
    component: ()=>import("@/views/tradingServices/tradingServices"),
    meta: {
      title: '挂牌'
    }
  },
  {
    // 挂牌交易
    path: '/trade/gp/:id',
    name: 'trade',
    component: ()=>import("@/views/trade"),
    meta: {
      title: '挂牌详情'
    }
  },
  {
    // 交易服务
    path: '/auction',
    name: 'auction',
    component: ()=>import("@/views/auction/auction"),
    meta: {
      title: '拍卖'
    }
  },
  {
    // 服务中心
    path: '/services',
    name: 'services',
    component: ()=>import("@/views/services"),
    meta: {
      title: '服务中心'
    }
  },
  {
    // 服务中心
    path: '/news-detail',
    name: 'news-detail',
    component: ()=>import("@/views/news-detail"),
    meta: {
      title: '资讯详情'
    }
  },
  {
    // 服务指南Guide
    path: '/service-guide',
    name: 'service-guide',
    component: ()=>import("@/views/news-detail"),
    meta: {
      title: '服务指南'
    }
  },
  {
    // 办事指南Guide
    path: '/work-guide',
    name: 'work-guide',
    component: ()=>import("@/views/news-detail"),
    meta: {
      title: '办事指南'
    }
  },
  {
    // 土地详情
    path: '/land/detail/:id',
    name: 'land-info',
    component: ()=>import("@/views/land-info"),
    meta: {
      title: '土地详情'
    }
  },
  {
    // 土地详情
    path: '/land/auction/:id',
    name: 'auction-info',
    component: ()=>import("@/views/auction-info"),
    meta: {

      title: '拍卖详情'
    }
  },
  {
    // 个人中心
    path: '/my',
    name: 'my',
    component: ()=>import("@/views/my/index"),
    meta: {
      title: '个人中心'
    },
    children:[

        {
            path: '/my/userinfo',
            name: 'userinfo',
            component: ()=>import("@/views/my/userinfo"),
            meta: {
                title: '我的账户'
            }
        }
        //我的收藏
        ,{
            path: '/my/favorite',
            name: 'favorite',
            component: ()=>import("@/views/my/favorite"),
            meta: {
                title: '我的收藏'
            }
        }
        //我的土地
        ,{
            path: '/my/land',
            name: 'myLand',
            component: ()=>import("@/views/my/land"),
            meta: {
                title: '我的土地'
            }
        }
        //我的需求
        ,{
            path: '/my/need',
            name: 'myNeed',
            component: ()=>import("@/views/my/need"),
            meta: {
                title: '求购信息'
            }
        }
        //我的合同
        ,{
            path: '/my/contract',
            name: 'myContract',
            component: ()=>import("@/views/my/contract"),
            meta: {
                title: '我的合同'
            }
        }  //我的撮合
        ,{
            path: '/my/match',
            name: 'myMatch',
            component: ()=>import("@/views/my/match"),
            meta: {
                title: '我的匹配'
            }
        } //我的竞价
      ,{
        path: '/my/my_apply',
        name: 'myApply',
        component: ()=>import("@/views/my/my_apply"),
        meta: {
          title: '挂牌竞价'
        }
      }
      ,{
        path: '/my/match_add',
        name: 'myMatchAdd',
        component: ()=>import("@/views/my/match_add"),
        meta: {
          title: '编辑匹配规则'
        }
      },
      {
        path: '/my/bidding_apply',
        name: 'biddingApply',
        component: ()=>import("@/views/my/bidding_apply"),
        meta: {
          title: '挂牌申请'
        }
      },
      {
        path: '/my/intention',
        name: 'intention',
        component: ()=>import("@/views/my/intention"),
        meta: {
          title: '意向出价'
        }
      },
      {
        path: '/my/reservation',
        name: 'reservation',
        component: ()=>import("@/views/my/reservation"),
        meta: {
          title: '看地预约'
        }
      },
      {
        path: '/my/intentionnew',
        name: 'intentionnew',
        component: ()=>import("@/views/my/intentionnew"),
        meta: {
          title: '意向出价'
        }
      },
      {
        path: '/my/reservationnew',
        name: 'reservationnew',
        component: ()=>import("@/views/my/reservationnew"),
        meta: {
          title: '看地预约'
        }
      },
      {
        path: '/my/bargain_apply',
        name: 'bargainApply',
        component: ()=>import("@/views/my/bargain_apply"),
        meta: {
          title: '拍卖申请'
        }
      },
      {
        path: '/my/my_bargain',
        name: 'myBargain',
        component: ()=>import("@/views/my/my_bargain"),
        meta: {
          title: '拍卖竞价'
        }
      },
      {
        path: '/my/agreement_transaction',
        name: 'agreementTransaction',
        component: ()=>import("@/views/my/agreement_trade"),
        meta: {
          title: '协议交易'
        }
      },
      {
        path: '/my/demand_trade',
        name: 'demandTrade',
        component: ()=>import("@/views/my/demand_trade"),
        meta: {
          title: '出租交易'
        }
      },
      {
        path: '/my/rent_land',
        name: 'rentLand',
        component: ()=>import("@/views/my/rent"),
        meta: {
          title: '求租信息'
        }
      }

    ]
  },
  {
    // 个人中心
    path: '/login',
    name: 'login',
    component: ()=>import("@/views/login"),
    meta: {
      title: '用户登录'
    }
  },
  //结束



  {
    //公告
    path: '/map',
    name: 'map',
    component: ()=>import("@/views/map.vue"),
    meta: {
      title: '地图找地'
    }
  },
  //结束



  {
    path: '/keji',
    name: 'keji',
    component: keji,
    meta: {
      title: '科技'
    }
  },
  {
    path: '/youjiao',
    name: 'youjiao',
    component: youjiao,
    meta: {
      title: '幼教'
    }
  },
  {
    path: '/jinrong',
    name: 'jinrong',
    component: jinrong,
    meta: {
      title: '金融'
    }
  },
  {
    path: '/zhijiao',
    name: 'zhijiao',
    component: zhijiao,
    meta: {
      title: '执教'
    }
  },
  {
    path: '/dianshang',
    name: 'dianshang',
    component: dianshang,
    meta: {
      title: '电商'
    }
  },
  {
    path: '/xinwen',
    component: news,
    children: [{
        path: '',
        component: GroupNews,
        name: 'GroupNews',
        meta: {
          title: '集团新闻'
        }
      },
      {
        path: 'MediaCoverage',
        component: MediaCoverage,
        name: 'MediaCoverage',
        meta: {
          title: '媒体报道'
        }
      },
      {
        path: 'TradeNews',
        name: 'TradeNews',
        component: TradeNews,
        meta: {
          title: '行业资讯'
        }
      },

    ],
    meta: {
      title: '集团新闻'
    }
  },
  {
    path: '/zhaopinxinxi0',
    name: 'zhaopinxinxi0',
    component: zhaopinxinxi0,
    meta: {
      title: '招聘信息0'
    }
  },
  {
    path: '/zhaopinxinxi1',
    name: 'zhaopinxinxi1',
    component: zhaopinxinxi1,
    meta: {
      title: '招聘信息1'
    }
  },
  {
    path: '/lianxi',
    name: 'lianxi',
    component: lianxi,
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/zhaopinxiangqing0_0',
    name: 'zhaopinxiangqing0_0',
    component: zhaopinxiangqing0_0,
    meta: {
      title: '招聘详情0-0'
    }
  },
  {
    path: '/zhaopinxiangqing0_1',
    name: 'zhaopinxiangqing0_1',
    component: zhaopinxiangqing0_1,
    meta: {
      title: '招聘详情0-1'
    }
  }, {
    path: '/zhaopinxiangqing1_0',
    name: 'zhaopinxiangqing1_0',
    component: zhaopinxiangqing1_0,
    meta: {
      title: '招聘详情1-0'
    }
  }, {
    path: '/zhaopinxiangqing1_1',
    name: 'zhaopinxiangqing1_1',
    component: zhaopinxiangqing1_1,
    meta: {
      title: '招聘详情1-1'
    }
  }
  ,
  {
    // 信息注册
    path: '/Register',
    name: 'Register',
    component: ()=>import("@/views/Register/index"),
    meta: {
      title: '信息注册'
    },
  },{
    path: '/register_user',
    name: 'register_user',
    component: ()=>import("@/views/Register/register_user"),
    meta: {
      title: '法人注册'
    }
  }, {
    path: '/register_enterprise',
    name: 'register_enterprise',
    component: ()=>import("@/views/Register/register_enterprise"),
    meta: {
      title: '企业注册'
    }
  }
  , {
    path: '/reset_password',
    name: 'reset_password',
    component: ()=>import("@/views/Register/reset_password"),
    meta: {
      title: '重置密码'
    }
  }

]

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})


const defaultTitle = "德阳市土地超市";
router.beforeEach((to, from, next) => {
    if(to.name === "login" && CommonService.isLogin()){
        next({name: "userinfo"});
        return;
    }

    if(to.path.startsWith("/my") && !CommonService.isLogin()){
        next({name: "login"});
        return;
    }
	document.title = to.meta.title ? defaultTitle + " - " + to.meta.title : defaultTitle;
	next();
});


export default router
