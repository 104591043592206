import Vue from 'vue'
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'
import request from "request";

//const API_URL = process.env.VUE_APP_API_URL;
 //const API_URL ="https://dytudi.com.cn/api";
const API_URL = "/api";

function getUrl(url) {
    return API_URL + url;
}

function get(url,params = {},config = {}) {
    params.randomStr = makeid(12);
    config.params = params;
    return Vue.axios.get(url,config).then((response) => response.data);
}

function post(url,params = {},config = {}) {
    return Vue.axios.post(url,params,config).then((response) => response.data);
}

function put(url,params = {},config = {}) {
    return Vue.axios.put(url,params,config).then((response) => response.data);
}

function del(url,data = {},config = {}) {
    config.data = data;
        return Vue.axios.delete(url,config).then((response) => response.data);
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}



function setAdminCookie(){
    if("guest" === localStorage.getItem("username")){
        return;
    }

    Cookies.set('refresh_token', localStorage.getItem("refresh_token"));
    Cookies.set('token', localStorage.getItem("access_token"));
}


/**
 * user services
 * @returns
 */
function login(username,password) {
    let url = "/auth/oauth2/token?username="+username+"&randomStr="+makeid(12)+"&grant_type=password&scope=server";

    const params = new URLSearchParams();
    params.append('password', aes(password));

    //?username=admin&randomStr=blockPuzzle&code=wLdAQRMyd347mRkNlN14NVuPHj%2B8TQ4HgUIm9L2g0vQtWEBZ94qZRsoTCFk98N94OSeI5Q5mGAQpYybbTeU16Fo4KWtH19XNHwHYQeFckbo%3D&grant_type=password&scope=server
    return post(getUrl(url),params,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    }).then((res) => {
        if (res && res.access_token) {
            localStorage.setItem("user_id", res.user_id)
            localStorage.setItem("access_token", res.access_token)
            localStorage.setItem("refresh_token", res.refresh_token)
            localStorage.setItem("token_type", res.token_type)
            localStorage.setItem("username", res.username)
            localStorage.setItem("exp", res.exp * 1000)
            setAdminCookie();
        }
        return res;
    });
}


function loginCode(phone,verifyCode) {
    let url = "/auth/oauth2/token?mobile=SMS@"+phone+"&code="+verifyCode+"&grant_type=mobile&scope=server&randomStr="+makeid(12);

    const params = new URLSearchParams();
      return post(getUrl(url),params,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    }).then((res) => {
        if (res && res.access_token) {
            localStorage.setItem("user_id", res.user_id)
            localStorage.setItem("access_token", res.access_token)
            localStorage.setItem("refresh_token", res.refresh_token)
            localStorage.setItem("token_type", res.token_type)
            localStorage.setItem("username", res.username)
            localStorage.setItem("exp", res.exp * 1000)
            localStorage.setItem("login_type", 'phone');
            setAdminCookie();
        }
        return res;
    });
}

function loginCaCode(param) {
  // param='CN=TEST@卓远征信测试证书123@18200509916@1,OU=Organizational-1,OU=TESTupdate,O=BOC,C=CN'
  let data_json=urlToJSON(param);
  let data_arra=data_json["CN"].split('@');
  if(data_arra.length<=0)
  {
      return null;
  }
  return   register(data_arra[1],data_arra[2],'',data_arra[3]).then((re)=>{
        console.log(re)
        let url = "/auth/oauth2/token?mobile=SMS@"+data_arra[2]+"&code=9527&grant_type=mobile&scope=server&randomStr="+makeid(12);
        console.log(data_arra);
        const params = new URLSearchParams();
        return post(getUrl(url),params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Tenant-id':1,
                'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
            }
        }).then((res) => {
            if (res && res.access_token) {
                localStorage.setItem("user_id", res.user_id)
                localStorage.setItem("access_token", res.access_token)
                localStorage.setItem("refresh_token", res.refresh_token)
                localStorage.setItem("token_type", res.token_type)
                localStorage.setItem("username", data_arra[0]);
                localStorage.setItem("login_type", 'ca');
                localStorage.setItem("exp", res.exp * 1000)
                setAdminCookie();
            }
            return res;
        });
    });


}
function urlToJSON(url) {
    let params = url.split(',');
    let obj = {}
    params.map(item => obj[item.split("=")[0]] = item.split("=")[1])
    return obj;
}



function sendLoginSmsCode(phone){
    let url = "/admin/mobile/"+phone;
    return get(getUrl(url),{

        mobile:phone,
        type:1
    });
}

function sendLoginSmsCodeNew(phone){
    let url = "/admin/mobile/mobilenew/"+phone;
    return get(getUrl(url),{

        mobile:phone,
        type:1
    });
}

function send_verify_code(phone){
    let url = "/admin/common_user/send_verify_code?phone="+phone;
    const params = new URLSearchParams();
    return post(getUrl(url),params,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    }).then((res) => {
        return res;
    });
}




function addCommonUser(username,password,phone,verifyCode)
{
    let url = "/admin/common_user";
    return post(getUrl(url), {
        "username":username,
        "password":password,
        "phone":phone,
        "verifyCode":verifyCode,
    },{
        headers: {
            'Content-Type': 'application/json',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    }).then((res) => {

        return res;
    });
}



function register(name,phone,email,idCard)
{
    let url = "/admin/user/register ";
    return post(getUrl(url), {
        "name":name,
        "phone":phone,
        "email":email,
        "idCard":idCard,
    },{
        headers: {
            'Content-Type': 'application/json',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    }).then((res) => {

        return res;
    });
}



function setUserPassword(password,phone,verifyCode)
{
    let url = "/admin/common_user/reset_password";
    const params = new URLSearchParams();
    params.append('password', password);
    params.append('phone', phone);
    params.append('verifyCode', verifyCode);

    return put(getUrl(url), {"password":password,"phone":phone,"verifyCode":verifyCode},{
        headers: {
            'Content-Type': 'application/json',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    })
}








function refreshToken(){
    return post(getUrl("/auth/oauth2/token?grant_type=refresh_token&refresh_token="+localStorage.getItem("refresh_token")),null,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Tenant-id':1,
            'Authorization': 'Basic bGFuZF93ZWJzaXRlOndlYnNpdGU='
        }
    });
}

function countByRegion(){
    return get(getUrl("/land/land_info/countByRegion"));
}

function getUserInfo(){
    return get(getUrl("/admin/user/info"));
}

function getDicts(type) {
    return get(getUrl(`/admin/dict/type/${type}`));
}



function getDistricts() {
    let url = "/admin/district/next/5106";
    return get(getUrl(url));
}

function getNews(params = {
    type:1,
    size:6,
    current:1,
}){
    let url = "/land/land_news/page";
    return get(getUrl(url),{
       ...params,
        status:1
    });
}

function getNewsDetail(id){
    let url = "/land/land_news/"+id;
    return get(getUrl(url));
}

function weatherInfo(){
    return get('https://restapi.amap.com/v3/weather/weatherInfo?key=eabe30a42a1b15b3984831d63f519dc1&city=510600&extensions=base&output=JSON');
}


function getLandInfo(){
    let url = "/admin/landinfo/page";
    return get(getUrl(url));
}

function getLandDetail(landId){
    let url = "/land/land_info/" + landId;
    return get(getUrl(url));
}

function getCarousel(groupName) {
	return get(getUrl('/land/pics/page'),{
        bannerGroup:groupName,
        current:1,
        size:100
    });
}

/**
 * 获取土地列表
 */
function getLandList(params={}){
    let url = "/land/land_info/page";
    return get(getUrl(url),params).then((res)=>{
        console.log(222)
        console.log(res)
        for (let i=0;i<res.data.records.length;i++){
            res.data.records[i].title= res.data.records[i].title.replace("出租出租",'出租')
            res.data.records[i].title= res.data.records[i].title.replace("转让转让",'转让')
        }
        return res;
    });
}

/**
 * 获取推荐土地列表
 */
function getRecommendLandList(){
    return getLandList({
        recommended:1,
        current:1,
        size:8
    });
}

function landSearch(params={}){
    let url = "/land/land_info/search";
    params.isPublished = 1;
  //  params.actualTransactionStatus='processing';
    let data= get(getUrl(url),params).then((res)=>{
        console.log(222)
        console.log(res)
        for (let i=0;i<res.data.records.length;i++){
            res.data.records[i].title= res.data.records[i].title.replace("出租出租",'出租')
            res.data.records[i].title= res.data.records[i].title.replace("转让转让",'转让')
        }
        return res;
    });

    return data;
}

/**
 * 获取需求土地列表
 */
function getDemandLandList(params={}){
    let url = "/land/land/page";
    return get(getUrl(url),params);
}

/**
 * 获取需求土地详情
 */
function getDemandDetail(landId){
    let url = "/land/land/"+landId;
    return get(getUrl(url));
}

/**
 * 获取需求土地详情
 */
function getRentDetail(landId){
    let url = "/land/rent_land/"+landId;
    return get(getUrl(url));
}

/**
 * 获取挂牌详情
 */
function getAuctionDetail(id){
    let url = "/land/auction_info/"+id;
    return get(getUrl(url));
}
function getAuctionListWithLand(query) {
    let url = "/land/auction_info/page_with_land";
    return get(getUrl(url), query);
}


function getLandNews(query) {
    let url = "/land/land_news/page";
    return get(getUrl(url), query);
}

function getBargainListWithLand(query) {
    let url = "/land/paimai_info/page_with_land";
    return get(getUrl(url), query);
}

/**
 * 查询出价
 * @param query
 * @returns
 */

function getAuctionPrices(params={}){
    let url = "/land/bidding_info/page";
    return get(getUrl(url),params);
}

function getBargainPrices(params={}){
    let url = "/land/paimai_bidding_info/page";
    return get(getUrl(url),params);
}


function getLandDetailWithAuction(id){
    let url = "/land/paimai_info/getByLand/" + id;
    return get(getUrl(url));
}
function getAuctionSpeaks(id){
    let url = "/land/auctioneer_speaks/page?paimaiId=" + id;
    return get(getUrl(url));
}
function applyAuction(params={}){
    let url = "/land/paimai_buyers";
    return post(getUrl(url),params);
}


function addAuctionPrice(params={}){
    let url = "/land/bidding_info";
    return post(getUrl(url),params);
}


/**
 * 获取土地转让信息
 */
function getLandTransferInfoList(params={}){
    let url = "/land/land_transfer_info/page";
    return get(getUrl(url),params);
}

/**
 * 获取土地转让信息
 */
function getLandTransferInfoByAuctionId(auctionId){
    let url = "/land/land_transfer_info/page";
    return get(getUrl(url),{
        auctionId
    }).then((res)=>{
        if(res && res.data.records && res.data.records.length>0){
            return {
                code:0,
                data:res.data.records[0]
            };
        }
        return {
            code:1,
            msg:"未找到相关信息"
        };
    });
}

/**
 * 获取自己的撮合列表
 */
function getMatchList(params={}){
    let url = "/land/match_transaction/page";
    return get(getUrl(url),params);
}


function getTradingHallList(params={}){
    let url = "/land/transaction_announcement/page";
    return get(getUrl(url),params);
}





/**
 * 收藏
 */
function addFavorite(landId,type){
    let url = "/land/my_favorites";
    return post(getUrl(url),{
        id:landId,
        type
    });
}

function getFavorite(params={}){
    let url = "/land/my_favorites/page";
    return get(getUrl(url),params);
}

function delFavorite(id,type){
    let url = "/land/my_favorites";
    return del(getUrl(url),{
        landId:id,type
    });
}

function checkFavorite(id,type){
    let url = "/land/my_favorites/check";
    return get(getUrl(url),{
        id,type
    });
}



function aes(data) {
    let key = "pigxpigxpigxpigx";
    key = CryptoJS.enc.Latin1.parse(key);
    var iv = key;
    // 加密
    var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding,
    });
	return encrypted.toString();
}

function isLogin(){
    let username = localStorage.getItem("username");
    let access_token = localStorage.getItem("access_token");
    return username && access_token && username !== "guest"
}

/**
 * 撮合交易配置
 */
export function addMatchConfig(param = {} ){
    let url = "/land/match_config";
    return post(getUrl(url),param);
}

export function getMatchConfig() {
    let url = "/land/match_config/my";
    return get(getUrl(url))
}

export function putMatchConfig(param = {} ){
    let url = "/land/match_config";
    return put(getUrl(url), param);
}


export function putObj(obj) {
    return put(getUrl('/land/land_info/'), obj);
}

export function getObj(id) {
    return get(getUrl('/land/land_info/' + id));
}

export function addObj(obj) {
    return post(getUrl('/land/land_info/'),  obj);
}

export function addNeedObj(obj) {
    return post(getUrl('/land/land/'), obj);
}



export  function getidCardInfo(obj){
    let url = "/admin/common/idCardInfo";
    return get(getUrl(url))
}

export function putUserINfo(param = {} ){
    let url = "https://land.dytudi.com.cn/admin/user";
    return put(url, param);
}



export  function  setidCardInfo(name,idCard,userId)  {

    let url = "/admin/common/idCardInfo";
    return put(getUrl(url),{
         name:name,
         idCard:idCard,
         userId:userId,
         checked:0
    });

}


export function getNeedObj(id) {
    let url = "/match_config";
    return get(getUrl('/land/land/' + id))
}

export function delNeedObjs(ids) {
    return del(getUrl('/land/land'), {
        data:ids,
    });
}

export function putNeedObj(obj) {
    return put(getUrl('/land/land_info'), obj);
}

export function getRentList(params={}){
    let url = "/land/rent_land/page";
    return get(getUrl(url),params);
}

export function getRentObj(id) {
    let url = "/match_config";
    return get(getUrl('/land/rent_land/' + id))
}

function getRentTransactionList(obj) {
    let url = "/land/rent_transaction/page_with_land";
    return get(getUrl(url), obj);
}

export function addRentObj(obj) {
    return post(getUrl('/land/rent_land/'), obj);
}

export function putRentObj(obj) {
    return put(getUrl('/land/rent_land'), obj);
}



/**
 * 获取需求土地详情资料
 */
function getAddFiles(id={},type){
    let url = "/land/transaction_identity/getAddFiles?transactionType="+type+"&transactionId="+id;
    return get(getUrl(url));
}


/**
 * 获取需求土地详情资料
 */
function land_news_add(params={}){
    let url = "/land/land_news";
    return post(getUrl(url), params)
}


/**
 * 获取需求土地详情资料
 */
function updateAddFiles(params={}){
    let url = "/land/transaction_identity/updateAddFiles";
    return post(getUrl(url), params)
}




function putAgreementTradeFiles(obj,type,objnew) {
    let url = "/land/paimai_info";
    if(type=='bargain')
    {
        url = "/land/transaction_identity";
        return post(getUrl(url), objnew);
    }else  if(type=="protocol"  ||type=="rent")
    {
        url = "/land/transaction_identity";
        return put(getUrl(url), objnew)
    }
    else {
        url = "/land/transaction_identity";
        return post(getUrl(url), objnew);
    }
    //交易类型不对, listing/protocol/auction/ren
//    let url = "/land/transaction_identity";
}
function addAgreementTradeFiles(obj,type,objnew) {
    let  url = "/land/transaction_identity";
    if(type=='bargain')
    {
      //  url = "/land/paimai_info";
    }else  if(type=="protocol"  ||type=="rent")
    {
        return post(getUrl(url), obj)
    }
    return post(getUrl(url), obj)
}

function getAgreementTradeFiles(id,type) {
    //let url = "/land/transaction_identity/" +id;

    let url = "/land/transaction_identity/" +id;

    if(type=='bargain')
    {
      //  url = "/land/paimai_info/" +id;
    }
    console.log(url)
    return get(getUrl(url))

}

function getAgreementTransactionList(obj) {
    let url = "/land/agreement_transaction/page_with_land";
    return get(getUrl(url), obj.value);
}


export function addBid(obj) {
    return post( getUrl('/land/bidding_info'),obj );
}

export function addBargain(obj) {
    return post( getUrl('/land/paimai_bidding_info'),obj );
}
export function fetchListingBuyerList(query) {
    return get(getUrl('/land/listing_buyers/page' ),query)
}

export function fetchAuctionBuyerList(query) {
    return get(getUrl('/land/paimai_buyers/page' ),query)
}


export function delObjs(ids) {
    return del(getUrl('/land/auction_info' ),ids)
}




/**
 *
 * @param obj 新增挂牌交易受让方
 * @returns
 */
 function addListingBuyer(obj) {
    return post( getUrl('/land/listing_buyers'),obj );
}
function getUserInfoById  (id) {
    return get(getUrl('/admin/user/details/'+ id ))
}




export default {
    getUserInfoById,
    addListingBuyer,
    getAgreementTradeFiles,
    addAgreementTradeFiles,
    putAgreementTradeFiles,
    getAgreementTransactionList,
    fetchListingBuyerList,
    fetchAuctionBuyerList,
    getRentTransactionList,
    putMatchConfig,
    addBid,
    getMatchConfig,
    addMatchConfig,
    updateAddFiles,
    getAddFiles,
    getUrl,
    setidCardInfo,
    getidCardInfo,
    get,
    post,
    login,
    isLogin,
    refreshToken,
    getUserInfo,
    getDistricts,
    getNews,
    getNewsDetail,
    getLandInfo,
    getCarousel,
    getLandList,
    landSearch,
    getRecommendLandList,
    getDemandLandList,
    getLandDetail,
    getDicts,
    getDemandDetail,
    addFavorite,
    getFavorite,
    delFavorite,
    checkFavorite,
    getAuctionDetail,
    register,
    countByRegion,
    getAuctionPrices,
    getBargainPrices,
    getAuctionListWithLand,
    getBargainListWithLand,
    addBargain,
    applyAuction,
    addAuctionPrice,
    getRentList,
    getLandDetailWithAuction,
    getAuctionSpeaks,
    getLandTransferInfoByAuctionId,
    send_verify_code,
    sendLoginSmsCode,
    addCommonUser,
    setUserPassword,
    getTradingHallList,
    getLandNews,
    loginCode,
    loginCaCode,
    sendLoginSmsCodeNew,
    putUserINfo,
    getMatchList,
    land_news_add,
    getRentDetail,
    weatherInfo
}
