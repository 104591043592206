<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-item-card">
        <div :class="'tag processing' " v-if="getTransactionStatus(landInfo.transactionStatus)">
           {{ getTransactionStatus(landInfo.transactionStatus) }}

        </div>
        <img :src="image" class="card-image" @click="toDetail" style="cursor: pointer;"/>
        <div class="card-content" style="padding: 14px">
            <!-- <h4><RouterLink to="/tradingServices">{{ landInfo.title }}</RouterLink></h4> -->
            <h4 @click="toDetail"  style="cursor: pointer;">
                {{ landInfo.title }}
            </h4>
            <div class="card-info-item">
                <i class="ti ti-dimensions"></i>
                <span>面积：</span>
                <span>{{ landInfo.landSize }} 平方米</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-map-pin"></i>
                <span>所属行政区：</span>
                <span>{{ getXzqName(landInfo.regionId) }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-home-hand"></i>
                <span>土地用途：</span>
                <span style="overflow: hidden; white-space: nowrap;text-overflow:ellipsis;">{{ getLandPurposeName(landInfo.landPurpose) }} <!-- {{getLandPurposeItemName(landInfo.landPurpose, landInfo.subLandPurpose)}}--></span>

            </div>
          <div>
            <a   target="_blank" :href="'https://dytudi.com.cn/api/land/land/wx/qrCode.png?path=pages/land/demand-list/qrcode_demand?id='+landInfo.id">
            <img  class="modeCard" :src="'https://dytudi.com.cn/api/land/land/wx/qrCode.png?path=pages/land/demand-list/qrcode_demand?id='+landInfo.id"></a>

          </div>
          <div id="magnifying-glass" style="display:none; position:absolute;"></div>
          <div style="clear: both">

          </div>
            <div class="card-info-item">
                <i class="ti ti-clock-hour-9"></i>
                <span>发布时间：</span>
                <span>{{ landInfo.createTime }}</span>
            </div>

        </div>
    </el-card>
</template>
<script>

import {useDict} from "@/hooks/dict";
import {computed} from "vue";



const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};


const transaction_status = {
    'processing': '正在交易',
    'success': '已成交'
}

export default {
    name: "LandItemCard",
    data() {
        return {
          isZoomed: false,
        };
    },

    props: {
      tabName:{
        type:String,
        default:''
      },
        landInfo: {
            type: Object,
            default: () => ({
                id: '',
                landPics: "",
                title: "name",
                landSize: 99.99,
                regionId: "武侯区",
                landPurpose: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
            }),
        }
    },
    components: {  },
    computed: {
      imageStyle() {
        return {
          transform: this.isHovered ? 'scale(1.2)' : 'scale(1)',
          transition: 'transform 0.3s ease-in-out',
        };
      },
        image() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",")[0] +'?w=300': "";
        },
       demandPurposeItemOptionsComputed (demandPurpose) {
        const options = useDict(demandPurpose);
        return options[demandPurpose].value;
      },

    },
    methods: {
      handleMouseEnter() {
        console.log(1)
        this.isHovered = true;
      },
      handleMouseLeave() {
        console.log(2)
        this.isHovered = false;
      },
        getXzqName(id) {
            return xzq[id];
        },
        getLandPurposeName(id) {
          let land_purposes = useDict('demand_purpose');
          let tmp = '';
          land_purposes.demand_purpose.value.forEach((item) => {
            if (item.value === id) {
              tmp =  item.label;
            }
          })
          return tmp;
        },
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
      getTransactionStatus(id){
            return transaction_status[id];
        },
        toDetail(){
            if(this.landInfo.auctionInfo &&  this.landInfo.transactionWay === "listing"){
                this.$router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
            }
           else if( this.landInfo.transactionWay === "auction" && this.landInfo.auctionInfo){
             this.$router.push("/land/auction/"+this.landInfo.id);
           }
            else{
                this.$router.push("/land/detail/"+this.landInfo.id);
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.modeCard{
  transition: all 0.5s;
  width: 70px;float: right; margin-top: -70px
}
.modeCard:hover{ //鼠标悬停时激活
  position: relative;
  z-index: 99;
  transition: 1s; //动画过渡的更加顺滑
  transform: scale(1.6); //放大倍数
}
</style>
